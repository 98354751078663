@charset "UTF-8";

// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "index";
@use "mixin" as mixin;
@use "color" as color;

// ==================================================
//  Hide
// ==================================================
.hide {
  &-pc {
    @include mixin.pc {
      display: none !important;
    }
  }
  &-sp {
    @include mixin.sp {
      display: none !important;
    }
  }
}

// ==================================================
//  Body
// ==================================================
body {
  // Noto Sans JP
  font-family: "Inter", "Noto Sans JP", "Hiragino Sans", "Hiragino Kaku Gothic ProN", YuGothic, "Yu Gothic", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: calc(24 / 16);
  min-width: 1120px;
  letter-spacing: 0.04em;

  @include mixin.sp {
    min-width: 375px;
    font-size: 1.4rem;
    min-width: 375px;
  }
}

// ==================================================
//  Header
// ==================================================
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 1120px;
  height: 90px;
  position: absolute;
  z-index: 90;
  top: 0;
  left: 0;
  background-color: color.$white;

  @include mixin.sp {
    min-width: 375px;
    position: fixed;
    height: 68px;
    border-bottom: 1px solid color.$grey-2;
  }

  // Javascript
  &.fixedScroll-js {
    // Modifier
    &-fix {
      @include mixin.pc {
        position: fixed;
        transform: translate3d(0, -100%, 0);
      }
    }
    &-show,
    &-hide {
      @include mixin.pc {
        transform: translate3d(0, 0, 0);
        transition: transform 0.3s ease;
      }
    }
  }

  &_logo {
    display: block;
    width: 210px;
    transform: translate(30px, 18px);
    margin: 0;

    @include mixin.sp {
      width: 140px;
      transform: translate(14px, 15px);
    }

    a,
    img {
      display: block;
      width: 100%;
    }

    a {
      @include mixin.hoverOpacity60;
    }
  }

  &_menu {
    display: flex;
    align-items: center;
    padding-right: 20px;

    @include mixin.sp {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      position: fixed;
      z-index: 90;
      top: 68px;
      left: 0;
      width: 100%;
      height: calc(100% - 68px);
      padding: 10px 24px 32px;
      background: color.$white;
      overflow: auto;
    }

    // Javascript
    &-js {
      @include mixin.sp {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
      }

      // Modifier
      &-active {
        @include mixin.sp {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &_navigation {
      display: block;

      @include mixin.pc {
        margin-right: 50px;
      }

      @include mixin.sp {
        width: 100%;
        max-width: 330px;
        margin: 0 auto;
      }

      &_list {
        display: flex;
        align-items: center;
        column-gap: 30px;
        list-style: none;
        margin: 0;
        padding: 0;

        @include mixin.sp {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
        }

        &_item {
          display: block;
          font-size: 1.5rem;
          line-height: calc(22 / 15);
          font-weight: 500;

          @include mixin.sp {
            width: 100%;
            border-bottom: 1px solid color.$grey-2;
          }

          &_inner {
            display: block;
            text-decoration: none;
            color: color.$site-write;
            @include mixin.hoverOpacity60;

            @include mixin.sp {
              padding: 20px 21px;
              position: relative;
            }

            &::before {
              @include mixin.sp {
                content: "";
                display: block;
                width: 8px;
                height: auto;
                aspect-ratio: 1/1;
                background-image: url(../img/common/arrow_b.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: auto 100%;
                position: absolute;
                top: 50%;
                right: 25px;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }

    &_tel {
      display: block;
      text-decoration: none;
      color: color.$site-write;

      @include mixin.pc {
        margin-right: 19px;
      }

      @include mixin.sp {
        order: 3;
        min-width: 290px;
        margin: 12px auto 0;
      }

      &_number {
        display: block;
        @include mixin.font-oswald;
        font-size: 2rem;
        line-height: calc(30 / 20);
        font-weight: 300;

        @include mixin.sp {
          font-size: 2.4rem;
          line-height: calc(36 / 24);
          color: color.$site-main;
          border: 1px solid color.$site-main;
          text-align: center;
          padding: 9px 16px;
        }

        &::before {
          content: "";
          display: inline-block;
          vertical-align: middle;
          width: 20px;
          height: auto;
          aspect-ratio: 1/1;
          background-image: url(../img/common/ico_tel.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: auto 100%;
          margin-right: 5px;
          transform: translateY(-1px);

          @include mixin.sp {
            width: 23px;
            transform: translateY(-2px);
            margin-right: 8px;
          }
        }
      }

      &_time {
        display: block;
        font-size: 1.3rem;
        line-height: calc(19 / 13);
        text-align: right;
        margin-top: -1px;

        @include mixin.sp {
          text-align: center;
          margin-top: 4px;
        }
      }
    }

    &_contact {
      display: block;
      min-width: 180px;
      text-decoration: none;
      text-align: center;
      color: color.$white;
      background-color: color.$site-main;
      padding: 17px 16px 16px;
      font-size: 1.6rem;
      line-height: calc(23 / 16);
      font-weight: 500;
      transition: background-color 0.3s ease;

      @include mixin.sp {
        order: 2;
        min-width: 290px;
        margin: 32px auto 0;
        font-size: 1.5rem;
        line-height: calc(22 / 15);
        padding: 17px 16px;
        position: relative;
      }

      &::before {
        @include mixin.pc {
          content: "";
          display: inline-block;
          width: 18px;
          height: auto;
          aspect-ratio: 1/0.778;
          background-image: url(../img/common/ico_mail.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: auto 100%;
          transform: translateY(1px);
          margin-right: 9px;
        }

        @include mixin.sp {
          content: "";
          display: block;
          width: 8px;
          height: auto;
          aspect-ratio: 1/1;
          background-image: url(../img/common/arrow_w.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: auto 100%;
          position: absolute;
          top: 50%;
          right: 32px;
          transform: translateY(-50%);
        }
      }

      @include mixin.hover {
        background-color: color.$bg-cta-active-blue;
      }
    }
  }

  &_menuButton {
    display: none;

    @include mixin.sp {
      display: block;
      width: 68px;
      border-left: 1px solid color.$grey-2;
      background-image: url(../img/common/menu.png);
      background-position: center top 17px;
      background-repeat: no-repeat;
      background-size: 38px auto;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    // Javascript
    &-js {
      @include mixin.sp {
        background-image: url(../img/common/menu.png);
      }

      // Modifier
      &-active {
        @include mixin.sp {
          background-image: url(../img/common/menu_close.png);
        }
      }
    }
  }
}

// ==================================================
//  Main
// ==================================================
.main {
  display: block;
  width: 100%;
  margin-top: 90px;

  @include mixin.sp {
    margin-top: 68px;
  }

  // Head
  &_head {
    display: block;
    width: 100%;
    padding: 28px 0 0 0;
    margin-bottom: 81px;

    @include mixin.sp {
      padding-top: 16px;
      margin-bottom: 40px;
    }

    &_title {
      display: block;
      width: 100%;
      padding: 33px 0 0 0;

      @include mixin.sp {
        padding-top: 28px;
      }

      &_en {
        display: block;
        @include mixin.font-oswald;
        font-size: 2rem;
        line-height: calc(30 / 20);
        margin: 0 0 8px;
        color: color.$site-main;

        @include mixin.sp {
          font-size: 1.4rem;
          line-height: calc(21 / 14);
          margin-bottom: 6px;
        }
      }

      &_ja {
        font-size: 4rem;
        line-height: calc(60 / 40);
        font-weight: 700;
        margin: 0;

        @include mixin.sp {
          font-size: 2.6rem;
          line-height: calc(38 / 26);
        }
      }
    }

    &_description {
      font-size: 1.6rem;
      line-height: calc(23 / 16);
      margin: 24px 0 0;

      @include mixin.sp {
        font-size: 1.3rem;
        line-height: calc(26 / 13);
        margin-top: 16px;
      }
    }
  }

  // Content Inner
  &_contentInner {
    display: block;
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;

    @include mixin.sp {
      max-width: calc(100% - 48px);
    }
  }

  // Button Link
  &_buttonLink {
    display: block;
    margin-top: 107px;

    @include mixin.sp {
      margin-top: 55px;
    }

    &_item {
      display: inline-block;
      font-size: 1.6rem;
      line-height: calc(23 / 16);
      color: initial;
      text-decoration: none;
      position: relative;
      padding-left: 104px;

      @include mixin.sp {
        font-size: 1.3rem;
        line-height: calc(19 / 13);
        padding-right: 29px;
        padding-left: 68px;
      }

      &::before {
        content: "";
        display: block;
        width: 10px;
        height: auto;
        aspect-ratio: 1/1;
        background-image: url(../img/common/arrow_b.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        position: absolute;
        top: 50%;
        left: 35px;
        transform: translateY(-50%) rotate(180deg);

        @include mixin.sp {
          width: 8px;
          left: 22px;
        }
      }

      &::after {
        content: "";
        display: block;
        width: 76px;
        height: auto;
        aspect-ratio: 1/1;
        opacity: 0.3;
        border: 2px solid color.$site-main;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%) rotate(180deg);
        transition: all 0.3s ease;

        @include mixin.sp {
          width: 52px;
        }
      }

      @include mixin.hover {
        &::after {
          width: 86px;
          left: -4px;
          opacity: 1;
          border-width: 1px;
        }
      }

      // Modifier
      &-right {
        padding-left: 0;
        padding-right: 104px;

        @include mixin.sp {
          padding-left: 29px;
          padding-right: 68px;
        }

        &::before {
          left: auto !important;
          right: 35px;
          transform: translateY(-50%);

          @include mixin.sp {
            right: 24px;
          }
        }

        &::after {
          left: auto !important;
          right: 0;
        }

        @include mixin.hover {
          &::after {
            right: -4px;
          }
        }
      }
    }
  }

  // Works List
  &_worksList {
    display: flex;
    flex-wrap: wrap;
    row-gap: 60px;
    width: 100%;
    margin-top: 31px;

    @include mixin.sp {
      flex-direction: column;
      row-gap: 0;
      margin-top: 40px;
    }

    &_item {
      display: block;
      width: calc(100% / 3);

      @include mixin.sp {
        width: 100%;
      }

      &_inner {
        color: initial;
        text-decoration: none;

        @include mixin.hover {
          .main_worksList_item_image {
            &::before {
              opacity: 0.4;
              visibility: visible;
            }
            &::after {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        @include mixin.sp {
          display: flex;
          align-items: flex-start;
          padding: 24px 0;
          border-top: 1px solid color.$grey-2;
          border-bottom: 1px solid color.$grey-2;
        }
      }

      &_image {
        display: block;
        width: 100%;
        height: auto;
        aspect-ratio: 1/0.667;
        position: relative;
        overflow: hidden;
        z-index: 1;

        @include mixin.sp {
          width: 140px;
        }

        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          background-color: color.$site-write;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.2s ease, visibility 0.2s ease;
        }
        &::after {
          content: "もっと見る";
          display: block;
          position: absolute;
          z-index: 3;
          top: 50%;
          left: calc(50% + 15px);
          transform: translate(-50%, -50%);
          font-size: 1.6rem;
          line-height: calc(30 / 16);
          font-weight: 500;
          color: color.$white;
          padding-right: 30px;
          background-image: url(../img/common/arrow_w.svg);
          background-size: 10px auto;
          background-position: right center;
          background-repeat: no-repeat;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.2s ease, visibility 0.2s ease;
        }

        & > img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &_text {
        display: block;
        width: 100%;
        padding: 20px 20px 30px;

        @include mixin.sp {
          width: calc(100% - 140px);
          padding: 0 0 0 16px;
        }

        &_title {
          font-size: 2rem;
          line-height: calc(29 / 20);
          font-weight: 500;
          margin: 0;

          @include mixin.sp {
            font-size: 1.4rem;
            line-height: calc(21 / 14);
          }
        }

        &_detail {
          display: block;
          width: 100%;
          margin: 12px 0 0;
          padding: 0;

          @include mixin.sp {
            margin: 4px 0 0;
          }

          &_inner {
            display: flex;
            column-gap: 16px;

            @include mixin.sp {
              column-gap: 10px;
            }
          }

          &_title {
            font-size: 1.4rem;
            line-height: calc(24 / 14);
            color: color.$grey-3;
            white-space: nowrap;

            @include mixin.sp {
              font-size: 1.1rem;
              line-height: calc(18 / 11);
            }
          }

          &_content {
            width: 100%;
            font-size: 1.4rem;
            line-height: calc(24 / 14);
            margin: 0;

            @include mixin.sp {
              font-size: 1.1rem;
              line-height: calc(18 / 11);
            }
          }
        }

        &_category {
          display: flex;
          flex-wrap: wrap;
          column-gap: 4px;
          row-gap: 4px;
          margin: 16px 0 0;

          @include mixin.sp {
            margin-top: 8px;
          }

          &_item {
            font-size: 1.2rem;
            line-height: calc(17 / 12);
            color: color.$grey-3;
            border: 1px solid color.$grey-3;
            border-radius: 30px;
            padding: 4px 10px 3px;
            white-space: nowrap;

            @include mixin.sp {
              font-size: 1rem;
              line-height: calc(14 / 10);
              padding: 2px 9px;
            }
          }
        }
      }
    }
  }
}

// ==================================================
//  CTA Contact
// ==================================================
.ctaContact {
  display: block;
  width: 100%;
  margin-top: 140px;
  padding: 68px 0 88px;
  background-color: color.$grey-1;

  @include mixin.sp {
    margin-top: 72px;
    padding: 56px 0 72px;
  }

  // Main Content Inner
  .main_contentInner {
    padding: 0 20px;

    @include mixin.sp {
      padding: 0 18px;
    }
  }

  &_head {
    display: block;
    width: 100%;
    text-align: center;

    &_en {
      display: block;
      @include mixin.font-oswald;
      font-size: 1.6rem;
      line-height: calc(24 / 16);
      color: color.$site-main;

      @include mixin.sp {
        font-size: 1.2rem;
        line-height: calc(18 / 12);
      }
    }

    &_ja {
      font-size: 3.6rem;
      font-weight: 700;
      line-height: 1;
      margin: 17px 0 0;

      @include mixin.sp {
        font-size: 2rem;
        line-height: calc(29 / 20);
        margin-top: 4px;
      }
    }

    &_description {
      font-size: 1.6rem;
      line-height: calc(24 / 16);
      margin: 32px 0 0;

      @include mixin.sp {
        font-size: 1.3rem;
        line-height: calc(26 / 13);
        margin-top: 12px;
      }
    }
  }

  &_link {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 44px;

    @include mixin.sp {
      flex-direction: column;
      row-gap: 20px;
      margin-top: 24px;
    }

    &_item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 50%;

      @include mixin.sp {
        width: 100%;
      }

      &:nth-of-type(2n) {
        @include mixin.pc {
          align-items: flex-end;
          border-left: 1px solid color.$grey-2;
        }
      }

      &_inner {
        width: 460px;
        text-align: center;

        @include mixin.sp {
          width: 100%;
        }
      }

      &_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        min-height: 100px;
        margin: 0;
        padding: 15px 30px;
        text-align: center;
        color: color.$site-main;
        background-color: color.$white;
        border: 1px solid color.$site-main;
        text-decoration: none;

        @include mixin.sp {
          max-width: 290px;
          min-height: 0;
          margin: 0 auto;
          padding: 9px 16px;
        }

        // Modifier
        &-form {
          font-size: 2.2rem;
          line-height: calc(32 / 22);
          color: color.$white;
          background-color: color.$site-main;
          position: relative;
          padding: 15px 80px;
          transition: background-color 0.3s ease;

          @include mixin.sp {
            font-size: 1.5rem;
            line-height: calc(22 / 15);
            padding: 16px;
          }

          &::before {
            content: "";
            display: block;
            width: 10px;
            height: auto;
            aspect-ratio: 1/1;
            background-image: url(../img/common/arrow_w.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: auto 100%;
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);
            transition: right 0.3s ease;

            @include mixin.sp {
              width: 8px;
              right: 22px;
            }
          }

          @include mixin.hover {
            background-color: color.$bg-cta-active-blue;

            &::before {
              right: 36px;
            }
          }
        }
        &-tel {
          @include mixin.font-oswald;
          font-size: 4rem;
          line-height: calc(59 / 40);
          font-weight: 300;

          @include mixin.sp {
            font-size: 2.4rem;
            line-height: calc(36 / 24);
          }

          &::before {
            content: "";
            display: block;
            width: 36px;
            height: auto;
            aspect-ratio: 1/1;
            background-image: url(../img/common/ico_tel_blue.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: auto 100%;
            margin-right: 8px;
            transform: translateY(2px);

            @include mixin.sp {
              width: 24px;
              transform: translateY(1px);
            }
          }
        }
      }

      &_text {
        font-size: 1.4rem;
        line-height: calc(24 / 14);
        margin: 12px 0 0 0;
        text-align: center;

        @include mixin.sp {
          font-size: 1.1rem;
          line-height: calc(16 / 11);
          margin-top: 8px;
          padding: 0 14px;
        }
      }
    }
  }
}

// ==================================================
//  Footer
// ==================================================
.footer {
  display: block;
  width: 100%;
  padding: 100px 0 0;

  @include mixin.sp {
    padding: 47px 0 0 0;
  }

  // Main Content Inner
  .main_contentInner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include mixin.sp {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &_information {
    @include mixin.sp {
      order: 2;
      display: block;
      width: 100%;
      margin-top: 38px;
    }

    &_logo {
      display: block;
      width: 309px;
      @include mixin.hoverOpacity60;

      @include mixin.sp {
        width: 240px;
      }

      & > img {
        display: block;
        width: 100%;
      }
    }

    &_address {
      font-style: normal;
      font-size: 1.4rem;
      line-height: calc(25 / 14);
      margin-top: 32px;

      @include mixin.sp {
        font-size: 1.2rem;
        line-height: calc(22 / 12);
        margin-top: 20px;
      }
    }
  }

  &_menu {
    display: block;

    @include mixin.sp {
      order: 1;
      width: 100%;
    }

    &_navigation {
      @include mixin.pc {
        display: flex;
        column-gap: 80px;
      }

      @include mixin.sp {
        display: block;
      }

      &_list {
        list-style: none;
        margin: 0;
        padding: 0;

        @include mixin.sp {
          width: calc(50% - 30px);
          min-height: 112px;
        }

        & > :first-child {
          margin-top: 0;
        }
        & > :last-child {
          margin-bottom: 0;
        }

        &:nth-of-type(1) {
          @include mixin.sp {
            float: left;
          }
        }
        &:nth-of-type(2) {
          @include mixin.sp {
            float: right;
          }
        }
        &:nth-of-type(3) {
          @include mixin.sp {
            float: left;
            margin-top: 37px;
          }
        }
        &:nth-of-type(4) {
          @include mixin.sp {
            float: right;
            margin-top: 20px;
          }
        }

        &_item {
          font-size: 1.3rem;
          line-height: calc(19 / 13);
          color: color.$grey-3;
          margin-bottom: 6px;

          @include mixin.sp {
            font-size: 1.2rem;
            margin-bottom: 13px;
            line-height: calc(17 / 12);
          }

          // Modifier
          &-head {
            font-size: 1.6rem;
            line-height: calc(23 / 16);
            font-weight: 500;
            color: color.$site-write;
            margin-top: 29px;
            margin-bottom: 13px;

            @include mixin.sp {
              font-size: 1.3rem;
              line-height: calc(19 / 13);
              margin-top: 20px;
              margin-bottom: 16px;
            }

            .footer_menu_navigation_list_item_inner {
            }
          }

          &_inner {
            color: inherit;
            text-decoration: none;
            @include mixin.hoverOpacity60;
          }
        }
      }
    }
  }

  &_privacy {
    display: block;
    width: 100%;
    text-align: right;
    margin-top: 140px;
    padding: 39px 0;
    border-top: 1px solid color.$grey-2;

    @include mixin.sp {
      order: 3;
      margin-top: 60px;
      padding: 38px 0;
    }

    &_link {
      display: inline-block;
      vertical-align: middle;
      color: color.$site-write;
      font-size: 1.4rem;
      line-height: calc(20 / 14);
      margin-right: 20px;

      @include mixin.sp {
        font-size: 1.2rem;
        margin-right: 16px;
        line-height: calc(15 / 12);
      }
    }
    &_copyright {
      display: inline-block;
      vertical-align: middle;
      font-size: 1.4rem;
      line-height: calc(17 / 14);
      margin: 0;
      color: color.$grey-3;

      @include mixin.sp {
        font-size: 1.2rem;
        line-height: calc(17 / 12);
      }
    }
  }
}

// ==================================================
//  Breadcrumb（WordPress Yoast SEO）
// ==================================================
.breadcrumb {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: auto;
  text-align: right;
  font-size: 1.3rem;
  line-height: calc(19 / 13);

  @include mixin.sp {
    font-size: 1.1rem;
    line-height: calc(16 / 11);
  }

  &_inner {
    display: block;
  }

  a {
    color: initial;
    text-decoration: none;
    @include mixin.hoverOpacity60;
  }

  &_last {
    color: color.$grey-3;
  }
}
